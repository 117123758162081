import { gql } from '@apollo/client'
import PropTypes from 'prop-types'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

import ErrorMessage from '@components/error_message'
import Page from '@components/page'
import { FOUR_OH_FOUR } from '@constants/errors'
import { LOCALES } from '@constants/locales'

const Custom404 = ({ country }) => (
  <Page
    country={country}
    query={FOUR_OH_FOUR_USER_QUERY}
    backgroundColour={Colours('white')}
  >
    <ErrorMessage
      type={FOUR_OH_FOUR}
      country={country}
    />
  </Page>
)

const FOUR_OH_FOUR_USER_QUERY = gql`
  query FourOhFourUserDataQuery {
    accountsViewer {
      ...PageViewer
    }
  }
  ${Page.fragments.viewer}
`

export async function getStaticProps() {
  return {
    props: {
      country: LOCALES.UNITED_KINGDOM
    }
  }
}

Custom404.propTypes = {
  country: PropTypes.string.isRequired
}

export default Custom404
